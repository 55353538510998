/* Import Skins */
@import url('dark-skin/skin.css');
@import url('light-skin/skin.css');



/* Reset Styles */
.ilightbox-overlay, .ilightbox-loader, .ilightbox-loader *, .ilightbox-holder, .ilightbox-holder .ilightbox-container, .ilightbox-holder .ilightbox-container img.ilightbox-image, .ilightbox-holder .ilightbox-container .ilightbox-caption, .ilightbox-toolbar, .ilightbox-toolbar *, .ilightbox-thumbnails, .ilightbox-thumbnails *, .ilightbox-holder .ilightbox-container .ilightbox-social, .ilightbox-holder .ilightbox-container .ilightbox-social * {
  float: none;
  padding: 0;
  margin: 0;
  font-size: 100%;
  line-height: 100%;
  vertical-align: baseline;
  -webkit-user-select:      none;
     -moz-user-select: -moz-none;
       -o-user-select:      none;
          user-select:      none;
  background: transparent;
  border: 0;
  outline: 0;

  -webkit-touch-callout: none;
}
.ilightbox-overlay, .ilightbox-loader, .ilightbox-loader *, .ilightbox-holder .ilightbox-container .ilightbox-caption, .ilightbox-toolbar, .ilightbox-thumbnails, .ilightbox-thumbnails *, .ilightbox-holder .ilightbox-container .ilightbox-social {
  -webkit-transform: translateZ(0);
     -moz-transform: translateZ(0);
}

/* Disable & Hide Scrollbar */
.ilightbox-noscroll {
  overflow: hidden;
}
.ilightbox-closedhand * {
  cursor: url(closedhand.cur),default !important;
}

.ilightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  display: none;
  width: 100%;
  height: 100%;
}

.ilightbox-loader {
  position: fixed;
  top: 45%;
  left: -192px;
  z-index: 100005;
  padding-left: 30px;
      filter: alpha(opacity=90);
  border-radius: 0 100px 100px 0;
  opacity: .9;

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
}

.ilightbox-loader div {
  width: 72px;
  height: 72px;
  border-radius: 0 100px 100px 0;
}

.ilightbox-loader.horizontal {
  top: -192px;
  left: 45%;
  padding: 0;
  padding-top: 30px;
  border-radius: 0 0 100px 100px;
}

.ilightbox-loader.horizontal  div {
  border-radius: 0 0 100px 100px;
}

.ilightbox-toolbar {
  position: fixed;
  z-index: 100010;
  display: none;
}

.ilightbox-toolbar a {
  float: left;
  cursor: pointer;
}

.ilightbox-toolbar .ilightbox-prev-button,
.ilightbox-toolbar .ilightbox-next-button {
  display: none;
}

.ilightbox-thumbnails {
  position: fixed;
  z-index: 100009;
  display: block;
}

.ilightbox-thumbnails.ilightbox-horizontal {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
}

.ilightbox-thumbnails.ilightbox-vertical {
  top: 0;
  right: 0;
  width: 140px;
  height: 100%;
  overflow: hidden;
}

.ilightbox-thumbnails .ilightbox-thumbnails-container {
  position: relative;
  display: block;
}

.ilightbox-thumbnails.ilightbox-horizontal .ilightbox-thumbnails-container {
  width: 100%;
  height: 100px;
}

.ilightbox-thumbnails.ilightbox-vertical .ilightbox-thumbnails-container {
  width: 140px;
  height: 100%;
}

.ilightbox-thumbnails .ilightbox-thumbnails-grid {
  position: absolute;
  display: block;
  -webkit-transform: translateZ(0);
     -moz-transform: translateZ(0);
}

.ilightbox-thumbnails .ilightbox-thumbnails-grid .ilightbox-thumbnail {
  position: relative;
  display: block;
  padding: 10px;
  cursor: pointer;
}

.ilightbox-thumbnails .ilightbox-thumbnails-grid .ilightbox-thumbnail img {
  width: 100%;
  height: 100%;
  border-radius: 2px;
  -ms-interpolation-mode: bicubic;
}

.ilightbox-thumbnails .ilightbox-thumbnails-grid .ilightbox-thumbnail .ilightbox-thumbnail-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .7;
}

.ilightbox-thumbnails .ilightbox-thumbnails-grid .ilightbox-thumbnail .ilightbox-thumbnail-icon:hover {
  opacity: 1;
}

.ilightbox-holder {
  position: fixed;
  z-index: 100003;
  display: none;
  -webkit-transform: none;
     -moz-transform: none;
}

.ilightbox-holder.ilightbox-next, .ilightbox-holder.ilightbox-prev {
  cursor: pointer;
}

.ilightbox-holder div.ilightbox-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.ilightbox-holder.supportTouch div.ilightbox-container {
  /*overflow: scroll;*/
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

.ilightbox-holder img.ilightbox-image {
  width: 100%;
  height: 100%;
}

.ilightbox-holder .ilightbox-container .ilightbox-caption {
  position: absolute;
  right: 30px;
  bottom: 0;
  left: 30px;
  z-index: 20003;
  display: none;
  max-width: 100%;
  box-sizing: border-box;
  padding: 5px 10px;
  margin: 0 auto;
  font-size: 12px;
  line-height: 150%;
  word-wrap: break-word;
  border-radius: 3px 3px 0 0;
}

.ilightbox-holder .ilightbox-container .ilightbox-social {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 20003;
  display: none;
  height: 16px;
  padding: 5px;
  padding-left: 0;
}

.ilightbox-holder .ilightbox-container .ilightbox-social ul {
  float: left;
  height: 16px;
  list-style: none;
}

.ilightbox-holder .ilightbox-container .ilightbox-social ul li {
  display: inline;
}

.ilightbox-holder .ilightbox-container .ilightbox-social ul li a {
  float: left;
  width: 16px;
  height: 16px;
  margin-left: 5px;
  background-repeat: no-repeat;
  background-position: 50%;
}

.ilightbox-holder .ilightbox-container .ilightbox-social ul li.facebook a {
  background-image: url('../social_icons/facebook_16.png');
}

.ilightbox-holder .ilightbox-container .ilightbox-social ul li.digg a {
  background-image: url('../social_icons/digg_16.png');
}

.ilightbox-holder .ilightbox-container .ilightbox-social ul li.twitter a {
  background-image: url('../social_icons/twitter_16.png');
}

.ilightbox-holder .ilightbox-container .ilightbox-social ul li.delicious a {
  background-image: url('../social_icons/delicious_16.png');
}

.ilightbox-holder .ilightbox-container .ilightbox-social ul li.reddit a {
  background-image: url('../social_icons/reddit_16.png');
}

.ilightbox-holder .ilightbox-container .ilightbox-social ul li.googleplus a {
  background-image: url('../social_icons/google_plus_16.png');
}

.ilightbox-holder .ilightbox-alert {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 300px;
  height: 50px;
  padding-top: 100px;
  margin: auto;
  text-align: center;
}

.ilightbox-holder .ilightbox-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.ilightbox-holder .ilightbox-inner-toolbar {
  position: relative;
  z-index: 100;
}

.ilightbox-holder .ilightbox-inner-toolbar .ilightbox-toolbar {
  position: absolute;
}

/* Style arrow buttons */
.ilightbox-button {
  position: fixed;
  z-index: 100008;
  cursor: pointer;
}

/* Style mobile devices */
.isMobile .ilightbox-button,
.isMobile .ilightbox-thumbnails {
  display: none !important;
}

.isMobile .ilightbox-toolbar .ilightbox-prev-button,
.isMobile .ilightbox-toolbar .ilightbox-next-button {
  display: block;
}
/*# sourceMappingURL=ilightbox.css.map */