.ilightbox-overlay.dark {
	background: #000;
}

.ilightbox-loader.dark {
	-webkit-box-shadow: black 0px 0px 85px, black 0px 0px 85px;
	        box-shadow: black 0px 0px 85px, black 0px 0px 85px;
}
.ilightbox-loader.dark div {
	background: #000 url('/css/dark-skin/preloader.gif') no-repeat center;
}

.ilightbox-holder.dark {
	padding: 5px;
	background: #000;
	
	-webkit-box-shadow: 0 0 15px hsla(0,0%,0%,.8);
	        box-shadow: 0 0 15px hsla(0,0%,0%,.8);
}

.ilightbox-holder.dark .ilightbox-container  .ilightbox-caption {
	background: url('/css/dark-skin/caption-bg.png');
	color: #FFF;
	text-shadow: 0 1px black;
}

.ilightbox-holder.dark .ilightbox-container .ilightbox-social {
	background: url('/css/dark-skin/caption-bg.png');
	
	-webkit-border-radius: 2px;
	        border-radius: 2px;
}

.ilightbox-holder.dark .ilightbox-alert {
	background: url('/css/dark-skin/alert.png') no-repeat center top;
	color: #555;
}

/* Style toolbar */
.ilightbox-toolbar.dark {
	top: 11px;
	left: 10px;
}

.ilightbox-toolbar.dark a {
	width: 25px;
	height: 23px;
	background: black url('/css/dark-skin/buttons.png') no-repeat 7px 6px;
}

.ilightbox-toolbar.dark a.disabled {
	opacity: 0.2;
	filter: alpha(opacity=20);
	cursor: default;
	background-color: #000 !important;
}

.ilightbox-toolbar.dark a:first-of-type {
	-webkit-border-bottom-left-radius: 3px;
	-webkit-border-top-left-radius: 3px;
		    border-bottom-left-radius: 3px;
		    border-top-left-radius: 3px;
}

.ilightbox-toolbar.dark a:last-of-type {
	-webkit-border-bottom-right-radius: 3px;
	-webkit-border-top-right-radius: 3px;
		    border-bottom-right-radius: 3px;
		    border-top-right-radius: 3px;
}

.ilightbox-toolbar.dark a.ilightbox-close:hover {
	background-position: -32px 6px;
}

.ilightbox-toolbar.dark a.ilightbox-fullscreen {
	background-position: 6px -33px;
	right: 35px;
}

.ilightbox-toolbar.dark a.ilightbox-fullscreen:hover {
	background-position: -31px -33px;
}

.ilightbox-toolbar.dark a.ilightbox-play {
	background-position: 8px -57px;
}

.ilightbox-toolbar.dark a.ilightbox-play:hover {
	background-position: -32px -57px;
}

.ilightbox-toolbar.dark a.ilightbox-pause {
	background-position: 8px -83px;
}

.ilightbox-toolbar.dark a.ilightbox-pause:hover {
	background-position: -32px -83px;
}

.isMobile .ilightbox-toolbar.dark {
	background: #000;
	top: auto;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 40px;
	text-align: center;
	
	-webkit-box-shadow: 0 0 25px rgba(0,0,0,.8);
	        box-shadow: 0 0 25px rgba(0,0,0,.8);
}

.isMobile .ilightbox-toolbar.dark a {
	display: inline-block;
	float: none;
	width: 50px;
	height: 40px;
	background-size: 50%;
	background-position: 50% !important;
}

.isMobile .ilightbox-toolbar.dark a:hover {
	background-color: #111;
}

.isMobile .ilightbox-toolbar.dark a.ilightbox-fullscreen {
	background-image: url('/css/dark-skin/fullscreen-icon-64.png');
	background-image/*\**/: url('/css/dark-skin/fullscreen-icon-ie.png')\9;  /* IE7-8 */
}

.isMobile .ilightbox-toolbar.dark a.ilightbox-close {
	background-image: url('/css/dark-skin/x-mark-icon-64.png');
	background-image/*\**/: url('/css/dark-skin/x-mark-icon-ie.png')\9;  /* IE7-8 */
}

.isMobile .ilightbox-toolbar.dark a.ilightbox-next-button {
	background-image: url('/css/dark-skin/arrow-next-icon-64.png');
	background-image/*\**/: url('/css/dark-skin/arrow-next-icon-ie.png')\9;  /* IE7-8 */
	background-position: 52% 50%;
}

.isMobile .ilightbox-toolbar.dark a.ilightbox-prev-button {
	background-image: url('/css/dark-skin/arrow-prev-icon-64.png');
	background-image/*\**/: url('/css/dark-skin/arrow-prev-icon-ie.png')\9;  /* IE7-8 */
	background-position: 48% 50%;
}

.isMobile .ilightbox-toolbar.dark a.ilightbox-play {
	background-image: url('/css/dark-skin/play-icon-64.png');
	background-image/*\**/: url('/css/dark-skin/play-icon-ie.png')\9;  /* IE7-8 */
}

.isMobile .ilightbox-toolbar.dark a.ilightbox-pause {
	background-image: url('/css/dark-skin/pause-icon-64.png');
	background-image/*\**/: url('/css/dark-skin/pause-icon-ie.png')\9;  /* IE7-8 */
}

.ilightbox-holder.dark .ilightbox-inner-toolbar .ilightbox-title {
	font-size: 18px;
	padding: 10px 8px;
	padding-right: 60px;
	color: #FFF;
}

.ilightbox-holder.dark .ilightbox-inner-toolbar .ilightbox-toolbar {
	left: auto;
	top: 5px;
	right: 5px;
}

.ilightbox-holder.dark .ilightbox-inner-toolbar .ilightbox-toolbar a {
	-webkit-border-radius: 0;
	        border-radius: 0;
	float: right;
}

.ilightbox-holder.dark .ilightbox-inner-toolbar .ilightbox-toolbar a:first-of-type {
	-webkit-border-bottom-right-radius: 3px;
	-webkit-border-top-right-radius: 3px;
		    border-bottom-right-radius: 3px;
		    border-top-right-radius: 3px;
}

.ilightbox-holder.dark .ilightbox-inner-toolbar .ilightbox-toolbar a:last-of-type {
	-webkit-border-bottom-left-radius: 3px;
	-webkit-border-top-left-radius: 3px;
		    border-bottom-left-radius: 3px;
		    border-top-left-radius: 3px;
}

/* Style thumbnails */
.ilightbox-thumbnails.dark .ilightbox-thumbnails-grid .ilightbox-thumbnail img {
	box-shadow: 0 0 6px rgba(0, 0, 0, .9);
}

.ilightbox-thumbnails.dark .ilightbox-thumbnails-grid .ilightbox-thumbnail .ilightbox-thumbnail-video {
	background: url('/css/dark-skin/thumb-overlay-play.png') no-repeat center;
}

/* Configure arrow buttons */
.ilightbox-button.dark.disabled {
	opacity: 0.1;
	filter: alpha(opacity=10);  /* IE7-8 */
	cursor: default;
	background-color: #000 !important;
}
.ilightbox-button.dark span {
	display: block;
	width: 100%;
	height: 100%;
}
.ilightbox-button.dark {
	bottom: 0;
	right: 0;
	left: 0;
	left/*\**/: 45%\9;  /* IE7-8 */
	right/*\**/: auto\9;  /* IE7-8 */
	width: 95px;
	height: 75px;
	margin: auto;
	background: url('/css/dark-skin/arrow-next-vertical-icon-64.png') no-repeat 50% 65%;
	background-color: #000;

	-webkit-border-radius: 0;
	        border-radius: 0;
	-webkit-border-top-left-radius: 5px;
	-webkit-border-top-right-radius: 5px;
		    border-top-left-radius: 5px;
		    border-top-right-radius: 5px;
}
.ilightbox-button.ilightbox-button.dark:hover {
	background-color: #111;
}
.ilightbox-button.ilightbox-prev-button.dark {
	top: 0;
	bottom: auto;
	background-image: url('/css/dark-skin/arrow-prev-vertical-icon-64.png');
	background-position: 50% 35%;

	-webkit-border-radius: 0;
	        border-radius: 0;
	-webkit-border-bottom-left-radius: 5px;
	-webkit-border-bottom-right-radius: 5px;
		    border-bottom-left-radius: 5px;
		    border-bottom-right-radius: 5px;
}
.ilightbox-button.dark.horizontal {
	right: 0;
	left: auto;
	top: 0;
	bottom: 0;
	top/*\**/: 41%\9;  /* IE7-8 */
	bottom/*\**/: auto\9;  /* IE7-8 */
	width: 75px;
	height: 95px;
	background-image: url('/css/dark-skin/arrow-next-icon-64.png');
	background-position: 65% 50%;

	-webkit-border-radius: 0;
	        border-radius: 0;
	-webkit-border-bottom-left-radius: 5px;
	-webkit-border-top-left-radius: 5px;
		    border-bottom-left-radius: 5px;
		    border-top-left-radius: 5px;
}
.ilightbox-button.ilightbox-prev-button.dark.horizontal {
	right: auto;
	left: 0;
	background-image: url('/css/dark-skin/arrow-prev-icon-64.png');
	background-position: 35% 50%;

	-webkit-border-radius: 0;
	        border-radius: 0;
	-webkit-border-bottom-right-radius: 5px;
	-webkit-border-top-right-radius: 5px;
		    border-bottom-right-radius: 5px;
		    border-top-right-radius: 5px;
}